
import { defineComponent, reactive, ref } from "vue";
import { useExpenseCategories } from "@/graphql/category/expense.categories";
import { activeActivity, subscriptionExpired } from "@/plugins/i18n";
import { useCreateCategory } from "@/graphql/category/category.create";
import ExpenseCategoryForm from "@/components/expense/ExpenseCategoryForm.vue";
import { useUpdateCategory } from "@/graphql/category/update.category";
import { Category } from "@/graphql/types";
import { usRemoveExpenseCategory } from "@/graphql/category/remove.expense.category";

export default defineComponent({
  name: "ExpenseCategory",
  components: { ExpenseCategoryForm },
  setup() {
    const common = {
      parentId: 1,
      type: 1,
    };
    const input = reactive({ labelCreation: "", labelUpdate: "", id: null });
    const expenseCatOverlay = ref<any>(null);
    const { loadCreation, submitCreation } = useCreateCategory(
      () => (input.labelCreation = "")
    );
    const { loadUpdate, submitUpdate } = useUpdateCategory(() =>
      expenseCatOverlay.value.hide()
    );
    function create() {
      submitCreation(null, {
        ...common,
        label: input.labelCreation,
        activityId: activeActivity.value.id,
      });
    }
    function update() {
      submitUpdate(null, {
        ...common,
        label: input.labelUpdate,
        id: input.id,
        removeImage: false,
      });
    }
    function setUpdate(event: any, category: Category) {
      input.id = category.id;
      input.labelUpdate = category.label;
      expenseCatOverlay.value.toggle(event);
    }
    return {
      ...useExpenseCategories(),
      subscriptionExpired,
      loadCreation,
      input,
      loadUpdate,
      update,
      create,
      expenseCatOverlay,
      setUpdate,
      ...usRemoveExpenseCategory(),
    };
  },
});
