import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('submit')), ["prevent"])),
    class: "p-inputgroup",
    style: {"width":"auto"}
  }, [
    _createVNode(_component_InputText, {
      "model-value": _ctx.modelValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
      placeholder: _ctx.$t('category.label')
    }, null, 8, ["model-value", "placeholder"]),
    _createVNode(_component_Button, {
      label: _ctx.$t(_ctx.icon.includes('plus') ? 'add' : 'modify'),
      icon: _ctx.icon,
      class: "p-inputgroup-addon",
      "icon-pos": "right",
      type: "submit",
      loading: _ctx.loading,
      disabled: !_ctx.modelValue || _ctx.loading
    }, null, 8, ["label", "icon", "loading", "disabled"])
  ], 32))
}