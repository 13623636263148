export const MenuSettings = {
  menuTypes: ["static", "overlay", "slim", "horizontal"],
  colorSchemes: ["dim", "light"],
  componentThemes: [
    { name: "blue", color: "#42A5F5" },
    { name: "lightgreen", color: "#9CCC65" },
  ],
  inputStyles: ["outlined", "filled"],
  menuThemes: [
    {
      name: "white",
      color: "#ffffff",
      class: "layout-sidebar-white",
    },
    {
      name: "darkgray",
      color: "#343a40",
      class: "layout-sidebar-darkgray",
    },
  ],
};
export const changeStyle = () => {
  function isIE() {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  }
  function replaceLink(linkElement: any, href: string) {
    if (isIE()) {
      linkElement.setAttribute("href", href);
    } else {
      const id = linkElement.getAttribute("id");
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute("href", href);
      cloneLinkElement.setAttribute("id", id + "-clone");

      linkElement.parentNode.insertBefore(
        cloneLinkElement,
        linkElement.nextSibling
      );

      cloneLinkElement.addEventListener("load", () => {
        linkElement.remove();
        cloneLinkElement.setAttribute("id", id);
      });
    }
  }
  function changeStyleSheetUrl(id: string, value: string, from: number) {
    const element = document.getElementById(id);
    const urlTokens = element.getAttribute("href").split("/");

    if (from === 1) {
      // which function invoked this function
      urlTokens[urlTokens.length - 1] = value;
    } else if (from === 2) {
      // which function invoked this function
      if (value !== null) {
        urlTokens[urlTokens.length - 2] = value;
      }
    } else if (from === 3) {
      // which function invoked this function
      urlTokens[urlTokens.length - 2] = value;
    }

    const newURL = urlTokens.join("/");

    replaceLink(element, newURL);
  }
  return { changeStyleSheetUrl };
};

export const featureIcons = {
  activity: "pi pi-book",
  category: "pi pi-sitemap",
  product: "pi pi-shopping-cart",
  stock: "pi pi-briefcase",
  customer: "pi pi-users",
  pos: "pi pi-money-bill",
  expense: "pi pi-id-card",
  rapport: "pi pi-folder",
  dashboard: "pi pi-table",
  user: "pi pi-users",
  account: "pi pi-user",
  workspace: "pi pi-table",
  administration: "pi pi-hashtag",
  "sub-payment": "pi pi-clock",
  subscription: "pi pi-money-bill",
  instigator: "pi pi-users",
  hr: "pi pi-users",
};
