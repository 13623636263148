import {
  ComparativeRapportInput,
  Expense,
  QueryPaginateExpensesArgs,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useLazyQuery, useResult } from "@vue/apollo-composable";
import { activeActivity } from "@/plugins/i18n";
import { reactive } from "vue";
import { EXPENSE_FIELDS } from "@/graphql/expense/expense";
import moment from "moment";

type PaginateExpensesData = {
  paginateExpenses: Expense[];
};
const PAGINATE_EXPENSES = gql`
    query PaginateExpenses($input: ComparativeRapportInput!) {
        paginateExpenses(input: $input) {
            ${EXPENSE_FIELDS}
        }
    }
`;

export const usePaginateExpenses = () => {
  const today = new Date();
  const input = reactive<ComparativeRapportInput>({
    activityId: activeActivity.value.id,
    startAt: moment(today).startOf("month").toDate(),
    endAt: today,
  });
  const { result, loading, load } = useLazyQuery<
    PaginateExpensesData,
    QueryPaginateExpensesArgs
  >(PAGINATE_EXPENSES);

  function loadData() {
    void load(PAGINATE_EXPENSES, {
      input: { ...input },
    });
  }

  const expenses = useResult<PaginateExpensesData, Expense[], Expense[]>(
    result,
    [],
    (res) =>
      res.paginateExpenses.map((expense) => ({
        ...expense,
        createdAt: new Date(expense.createdAt),
      }))
  );
  loadData(); // call function to init data
  return { loading, expenses, input, loadData, today };
};
