import { MutationRemoveCategoryArgs } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useMutation } from "@vue/apollo-composable";
import { useConfirm } from "primevue/useconfirm";

type RemoveExpenseCategoryData = {
  removeExpenseCategory: boolean;
};

const REMOVE_CATEGORY = gql`
  mutation RemoveExpenseCategory($id: Int!) {
    removeExpenseCategory(id: $id)
  }
`;
export const usRemoveExpenseCategory = () => {
  const toast = useToast();
  const { t } = useI18n();
  const confirm = useConfirm();
  const { mutate, onDone } = useMutation<
    RemoveExpenseCategoryData,
    MutationRemoveCategoryArgs
  >(REMOVE_CATEGORY);
  onDone(({ data, errors }) => {
    if (errors) {
      toast.add({
        severity: "warn",
        summary: t("delete.title"),
        detail: t("category.hasExpense"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    } else {
      toast.add({
        severity: data?.removeExpenseCategory ? "success" : "warn",
        summary: t("delete.title"),
        detail: t(
          `delete.${data?.removeExpenseCategory ? "success" : "failed"}`
        ),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
  });

  function submitRemove(id: number) {
    const data = {
      message: t("category.expenseWarning"),
      header: t("delete.title"),
      group: "dialog",
      accept: () =>
        void mutate(
          { id },
          {
            update(cache, { data }) {
              if (data?.removeExpenseCategory) {
                cache.modify({
                  fields: {
                    expenseCategories(existingRef: any[], { readField }) {
                      return existingRef.filter(
                        (eRef) => readField("id", eRef) !== id
                      );
                    },
                  },
                });
              }
            },
          }
        ),
      icon: "pi pi-exclamation-triangle",
      rejectLabel: t("no"),
      acceptLabel: t("yes"),
      acceptClass: "p-button-warning",
    };
    confirm.require(data);
  }
  return { submitRemove };
};
