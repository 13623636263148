import {
  Category,
  UpdateCategoryInput,
  MutationUpdateCategoryArgs,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { CATEGORY_FIELDS } from "@/graphql/category/category";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useMutation } from "@vue/apollo-composable";

type UpdateCategoryData = {
  updateCategory: Category;
};

const UPDATE_CATEGORY = gql`
    mutation UpdateCategory($image: Upload, $input: UpdateCategoryInput!) {
        updateCategory(image: $image, input: $input) {
            ${CATEGORY_FIELDS}
        }
    }
`;
export const useUpdateCategory = (callback: (v: boolean) => void) => {
  const toast = useToast();
  const { t } = useI18n();
  const {
    loading: loadUpdate,
    mutate,
    onError,
    onDone,
  } = useMutation<UpdateCategoryData, MutationUpdateCategoryArgs>(
    UPDATE_CATEGORY,
    { context: { hasUpload: true } }
  );
  onDone(({ data }) => {
    const success = !!data?.updateCategory;
    toast.add({
      severity: success ? "success" : "warn",
      summary: t("category.update.title"),
      detail: t(`${success ? "category.update.success" : "update.failed"}`),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
    callback(!success);
  });
  onError(() => {
    toast.add({
      severity: "warn",
      summary: t("category.update.title"),
      detail: t("networkError"),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
  });

  function submitUpdate(image: File, input: UpdateCategoryInput) {
    void mutate({ image, input });
  }
  return { loadUpdate, submitUpdate };
};
