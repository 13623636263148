export const MOVEMENT_FIELDS = `
    id
    stockId
    ticketId
    quantity
    qStock
    createdAt
    price
    vat
    motif
`;
