import {
  ActiveSequenceOutput,
  QueryActiveSequenceArgs,
  Terminal,
  Ticket,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { SEQUENCE_FIELDS } from "@/graphql/sequence/sequence";
import { ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { activeActivity } from "@/plugins/i18n";
import { useQuery, useResult } from "@vue/apollo-composable";
import { CONSTANTS, formatDate } from "@/graphql/utils/utils";
import { EXPENSE_FIELDS } from "@/graphql/expense/expense";
import { TICKET_FIELDS } from "@/graphql/ticket/ticket";
import { MOVEMENT_FIELDS } from "@/graphql/movement/movement";
import { PAYMENT_RECEIPT_FIELDS } from "@/graphql/payment-receipt/payment-receipt";
import { CUSTOMER_FIELDS } from "@/graphql/customer/customer.sdl";
import { useCreateSequence } from "@/graphql/sequence/create.sequence";
import { NOT_STORABLE_FIELDS } from "@/graphql/product/not-storable";
import { useRouter } from "vue-router";

export type ActiveSequenceData = {
  activeSequence: ActiveSequenceOutput;
};

export type MenuItem = {
  label?: string;
  icon?: string;
  disabled?: boolean;
  separator?: boolean;
  command?: () => void;
  badge?: number;
  style?: string;
};

export const ACTIVE_SEQUENCE = gql`
  query ActiveSequence($terminalId: String!) {
    activeSequence(terminalId: $terminalId) {
      sequence {
        ${SEQUENCE_FIELDS}
        terminal {
          id
          name
          description
        }
        opener {
          id
          name
        }
        closer {
          id
          name
        }
        expenses {
            ${EXPENSE_FIELDS}
        }
      }
      tickets {
        ${TICKET_FIELDS}
        payments {
          ${PAYMENT_RECEIPT_FIELDS}
        }
        customer {
          ${CUSTOMER_FIELDS}
        }
        movements {
          ${MOVEMENT_FIELDS}
          product {
            id
            name
            changeable
            reference
            notStorable {
              ${NOT_STORABLE_FIELDS}
            }
          }
          stock {
            id
            inclTax
            quantity
          }
        }
      }
    }
  }
`;

export const getActiveTerminal = () =>
  JSON.parse(localStorage.getItem(CONSTANTS.activeTerminal)) as Terminal;

export const useActiveSequence = (callback: () => void) => {
  const { t } = useI18n();
  const terminal = getActiveTerminal();
  const menuItems = ref<MenuItem[]>([
    {
      label: "sale",
      icon: "pi pi-shopping-bag",
    },
    {
      label: "credit",
      icon: "pi pi-credit-card",
      badge: 0,
    },
    {
      label: "waiting",
      icon: "pi pi-ticket",
      badge: 0,
    },
    {
      label: "ticket",
      icon: "pi pi-ticket",
      badge: 0,
    },
    {
      label: "expense",
      icon: "pi pi-wallet",
      badge: 0,
    },
    {
      label: "sequence",
      icon: "pi pi-lock",
    },
  ]);
  const { csLoading, submitSeqCreation } = useCreateSequence(() => callback());
  const seqItems = ref<MenuItem[]>([
    {
      label: t("pos.openedAt"),
      icon: "pi pi-calendar-plus",
      disabled: true,
    },
    {
      separator: true,
    },
    {
      label: t("pos.open"),
      icon: "pi pi-unlock",
      disabled: true,
      command: () => {
        seqItems.value[2].disabled = true;
        submitSeqCreation();
      },
    },
    {
      label: t("pos.close"),
      icon: "pi pi-lock",
      disabled: true,
      command: () => {
        menu.value = "sequence";
      },
    },
    {
      separator: true,
    },
    {
      label: terminal.name,
      icon: "pi pi-tablet",
      disabled: true,
    },
  ]);
  const selectedTicket = ref<Ticket[]>([]);
  const menu = ref<string>("sale");
  const { currentRoute } = useRouter();
  const {
    loading: asLoading,
    result,
    refetch,
  } = useQuery<ActiveSequenceData, QueryActiveSequenceArgs>(ACTIVE_SEQUENCE, {
    terminalId: terminal.id,
  });

  watch(
    () => currentRoute.value,
    (route) => {
      if (route.name === "pos") {
        const { id } = getActiveTerminal();
        if (id != terminal.id) void refetch({ terminalId: id });
      }
    },
    { immediate: true }
  );

  function isSessionSale(t: Ticket, sequenceId: string, withCancel = true) {
    const status = [0, 1, 3, 4];
    if (withCancel) status.push(5);
    return status.includes(t.status) && t.sequenceId === sequenceId;
  }
  const active = useResult<
    ActiveSequenceData,
    ActiveSequenceOutput,
    ActiveSequenceOutput
  >(
    result,
    {
      sequence: null,
      tickets: [],
    },
    (res) => {
      if (activeActivity.value.id > 0) {
        menuItems.value[1].badge = 0; //credit
        menuItems.value[2].badge = 0; // waiting ticket
        menuItems.value[3].badge = 0; //sale
        menuItems.value[4].badge = 0; //expense
        const sequence = res.activeSequence.sequence;
        res.activeSequence.tickets.forEach((t) => {
          menuItems.value[1].badge += t.status === 1 ? 1 : 0;
          menuItems.value[2].badge += t.status === 2 ? 1 : 0;
          menuItems.value[3].badge += isSessionSale(t, sequence?.id, false)
            ? 1
            : 0;
          if (t.id === selectedTicket.value[0]?.id) selectedTicket.value[0] = t;
        });

        if (sequence) {
          menuItems.value[4].badge = sequence.expenses.length;
          seqItems.value[3].disabled = !!sequence.closedAt;
          seqItems.value[2].disabled = !seqItems.value[3].disabled;
          menuItems.value[5].icon = `pi pi-lock${
            seqItems.value[2].disabled ? "-open" : ""
          }`;
          seqItems.value[0].label =
            formatDate(sequence.openedAt, t("format.date")) +
            ", N°" +
            sequence.number;
        } else {
          seqItems.value[2].disabled = false;
          seqItems.value[3].disabled = true;
          seqItems.value[0].label = t("pos.openedAt");
        }
      }
      return res.activeSequence;
    }
  );
  function filter(status: number[], menu = "") {
    return active.value.tickets.filter((t) => {
      if (menu === "sales") return isSessionSale(t, active.value.sequence?.id);
      return status.includes(t.status);
    });
  }
  const tickets = computed(() => ({
    credit: filter([1]),
    waiting: filter([2]),
    sales: filter([0, 1, 3, 4, 5], "sales"),
  }));

  const ticketInfos = computed(() => {
    if (!selectedTicket.value.length || selectedTicket.value[0].status < 4)
      return "";
    else if (selectedTicket.value[0].status === 4) {
      //find returned ticket if it's cancelle ticket
      const { number } = tickets.value.sales.find(
        (tic) => tic.parentId === selectedTicket.value[0].id
      );
      return t("ticket.cancelledBy", { number: number });
    } else {
      //find cancelled ticket if it's returned ticket
      const { number } = tickets.value.sales.find(
        (tic) => tic.id === selectedTicket.value[0].parentId
      );
      return t("ticket.returnedBy", { number: number });
    }
  });

  return {
    asLoading,
    seqItems,
    active,
    menuItems,
    selectedTicket,
    csLoading,
    tickets,
    menu,
    terminal,
    ticketInfos,
  };
};
