import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExpenseCategoryForm = _resolveComponent("ExpenseCategoryForm")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DataTable, {
      value: _ctx.categories,
      rows: _ctx.categories.length,
      "responsive-layout": "scroll",
      class: "p-datatable-gridlines p-datatable-sm",
      globalFilterFields: ['label'],
      "auto-layout": "",
      loading: _ctx.loading,
      dataKey: "id",
      rowHover: false
    }, {
      header: _withCtx(() => [
        _createVNode(_component_ExpenseCategoryForm, {
          loading: _ctx.loadCreation,
          onSubmit: _ctx.create,
          modelValue: _ctx.input.labelCreation,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input.labelCreation) = $event)),
          icon: "pi pi-plus"
        }, null, 8, ["loading", "onSubmit", "modelValue"])
      ]),
      empty: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("category.none")), 1)
      ]),
      loading: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("loading")), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          sortable: true,
          field: "label",
          header: _ctx.$t('category.label')
        }, null, 8, ["header"]),
        _createVNode(_component_Column, {
          sortable: true,
          field: "createdAt",
          header: "Date de création"
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(_ctx.$d(data.createdAt, "long")), 1)
          ]),
          _: 1
        }),
        (!_ctx.subscriptionExpired())
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 0,
              header: _ctx.$t('controls')
            }, {
              body: _withCtx(({ data }) => [
                _createVNode(_component_Button, {
                  onClick: ($event: any) => (_ctx.setUpdate($event, data)),
                  icon: "pi pi-pencil",
                  class: "p-button-text p-button-rounded"
                }, null, 8, ["onClick"]),
                _createVNode(_component_Button, {
                  onClick: ($event: any) => (_ctx.submitRemove(data.id)),
                  icon: "pi pi-trash",
                  class: "p-button-text p-button-danger p-button-rounded"
                }, null, 8, ["onClick"])
              ]),
              _: 1
            }, 8, ["header"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["value", "rows", "loading"]),
    _createVNode(_component_OverlayPanel, {
      ref: "expenseCatOverlay",
      dismissable: true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ExpenseCategoryForm, {
          loading: _ctx.loadUpdate,
          onSubmit: _ctx.update,
          modelValue: _ctx.input.labelUpdate,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.input.labelUpdate) = $event)),
          icon: "pi pi-pencil"
        }, null, 8, ["loading", "onSubmit", "modelValue"])
      ]),
      _: 1
    }, 512)
  ], 64))
}