import {
  Category,
  CreateCategoryInput,
  MutationCreateCategoryArgs,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { CATEGORY_FIELDS } from "@/graphql/category/category";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useMutation } from "@vue/apollo-composable";

type CreateCategoryData = {
  createCategory: Category;
};

const CREATE_CATEGORY = gql`
    mutation CreateCategory($image: Upload, $input: CreateCategoryInput!) {
        createCategory(image: $image, input: $input) {
            ${CATEGORY_FIELDS}
        }
    }
`;
export const useCreateCategory = (callback: () => void) => {
  const toast = useToast();
  const { t } = useI18n();
  const {
    loading: loadCreation,
    mutate,
    onDone,
  } = useMutation<CreateCategoryData, MutationCreateCategoryArgs>(
    CREATE_CATEGORY,
    { context: { hasUpload: true } }
  );
  onDone(({ data }) => {
    const success = !!data?.createCategory;
    toast.add({
      severity: success ? "success" : "warn",
      summary: t("create.title"),
      detail: t(`${success ? "category.create.success" : "create.failed"}`),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
    if (success) callback();
  });
  function submitCreation(image: File, input: CreateCategoryInput) {
    void mutate(
      { image, input },
      {
        update: (cache, { data }) => {
          if (data?.createCategory) {
            if (data.createCategory.type === 0) {
              cache.modify({
                fields: {
                  productCategories(existingRef: any, { toReference }) {
                    return [...existingRef, toReference(data.createCategory)];
                  },
                },
              });
            } else {
              cache.modify({
                fields: {
                  expenseCategories(existingRef: any, { toReference }) {
                    return [...existingRef, toReference(data.createCategory)];
                  },
                },
              });
            }
          }
        },
      }
    );
  }
  return { loadCreation, submitCreation };
};
