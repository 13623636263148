export const EXPENSE_FIELDS = `
    id
    amount
    motif
    sequenceId
    createdAt
    category {
        id
        label
    }
`;
