import { MutationCreateSequenceArgs, Sequence } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { SEQUENCE_FIELDS } from "@/graphql/sequence/sequence";
import { useMutation } from "@vue/apollo-composable";
import { useI18n } from "vue-i18n";
import { useToast } from "primevue/usetoast";
import { EXPENSE_FIELDS } from "@/graphql/expense/expense";
import { getActiveTerminal } from "@/graphql/sequence/active.sequence";
import { addPaginationCache } from "@/graphql/utils/pagination";
import { activeActivity } from "@/plugins/i18n";

type CreateSequenceData = {
  createSequence: Sequence;
};

const CREATE_SEQUENCE = gql`
    mutation CreateSequence($input: SequenceCreateInput!) {
        createSequence(input: $input) {
          ${SEQUENCE_FIELDS}
          terminal {
            id
            name
            description
          }
          opener {
            id
            name
          }
          closer {
            id
            name
          }
          expenses {
            ${EXPENSE_FIELDS}
          }
        }
    }
`;

export const useCreateSequence = (callback: () => void) => {
  const { t } = useI18n();
  const toast = useToast();
  const {
    loading: csLoading,
    mutate,
    onDone,
  } = useMutation<CreateSequenceData, MutationCreateSequenceArgs>(
    CREATE_SEQUENCE,
    {
      update: (cache, { data }) => {
        if (data?.createSequence) {
          const { expenses, terminal, ...res } = data.createSequence;
          cache.modify({
            fields: {
              activeSequence(_, { toReference }) {
                return toReference({ expenses, ...res });
              },
              paginateSequences(existingRef: any, { toReference }) {
                return addPaginationCache(
                  { terminal, ...res },
                  existingRef,
                  toReference
                );
              },
            },
          });
        }
      },
    }
  );
  onDone(({ data }) => {
    const success = !!data?.createSequence;
    toast.add({
      severity: success ? "success" : "warn",
      summary: t("pos.sequence"),
      detail: t(`pos.${success ? "opened" : "failed"}`),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
    if (success) callback();
  });
  function submitSeqCreation() {
    void mutate({
      input: {
        terminalId: getActiveTerminal().id,
        activityId: activeActivity.value.id,
      },
    });
  }
  return {
    csLoading,
    submitSeqCreation,
  };
};
