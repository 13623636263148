
import { defineComponent } from "vue";
import { usePaginateExpenses } from "@/graphql/expense/paginate.expenses";
import ExpenseTable from "@/components/expense/ExpenseTable.vue";
import { featureIcons } from "@/layouts/workspace/menu-settings";
import ExpenseCategory from "@/components/expense/ExpenseCategory.vue";

export default defineComponent({
  name: "Expense",
  components: { ExpenseTable, ExpenseCategory },
  setup() {
    return {
      ...usePaginateExpenses(),
      featureIcons,
    };
  },
});
